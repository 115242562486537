const light = {
  primary: '#AB47BC',
  secondary: '#3A3A3D',
  background: '#e5e5e5'
};

const dark = {
  primary: '#2A73C5',
  background: '#e5e5e5'
};

export { light, dark };
