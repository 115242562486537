import Vue from 'vue';
import VueI18n from 'vue-i18n';
import nl from '../locales/nl-NL';

Vue.use(VueI18n);

const loadedLocales = ['nl-NL']; // as a fallback

const i18n = new VueI18n({
  locale: 'nl-NL',
  messages: {
    'nl-NL': nl,
  },
  fallbackLocale: 'nl-NL',
  numberFormats: {
    'nl-NL': {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
  },
});

function switchI18nLanguage(locale) {
  i18n.locale = locale;
}

export const switchLanguage = async (locale) => {
  if (i18n.locale === locale) return;
  if (loadedLocales.includes(locale)) {
    switchI18nLanguage(locale);
    return;
  }

  const language = await import(`../locales/${locale}`);
  loadedLocales.push(locale);
  i18n.setLocaleMessage(locale, language.default);

  switchI18nLanguage(locale);
};

export { i18n as default };
