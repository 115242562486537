import AuthorisationGuard from '@/router/guards/AuthorisationGuard.js';

export default {
  path: '',
  beforeEnter: AuthorisationGuard,
  component: () => import('@/templates/Default'),
  children: [
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/modules/dashboard/views/Dashboard.vue'),
    },
    {
      path: 'user',
      name: 'user.index',
      component: () => import('@/modules/user/views/UserResource.vue'),
    },
    {
      path: 'product',
      name: 'product.index',
      component: () => import('@/modules/product/views/ProductResource.vue'),
    },
    {
      path: 'product-categories',
      name: 'product-category.index',
      component: () => import('@/modules/product/views/ProductCategoryResource.vue'),
    },
    {
      path: 'tickets',
      name: 'tickets.index',
      component: () => import('@/modules/tickets/views/TicketsList.vue'),
    },
    {
      path: 'tickets/:ticketId',
      name: 'tickets.show',
      component: () => import('@/modules/tickets/views/TicketsDetail.vue')
    },
    {
      path: 'orders',
      name: 'orders.index',
      component: () => import('@/modules/orders/views/OrdersList.vue'),
    },
    {
      path: 'orders/:orderId',
      name: 'orders.show',
      component: () => import('@/modules/orders/views/OrdersDetail.vue')
    },
    {
      path: 'location-details',
      name: 'locationDetails',
      component: () => import('@/modules/locationDetails/views/LocationDetails.vue')
    },
    {
      path: 'exchange-rates',
      name: 'exchange-rate.index',
      component: () => import('@/modules/exchangeRate/views/ExchangeRateResource.vue')
    },
  ],
};
