import actions from './actions.json';
import authorisation from './authorisation.json';
import client from './client.json';
import dashboard from './dashboard.json';
import global from './global.json';
import locale from './locale.json';
import product from './product.json';
import productCategory from './productCategory.json';
import user from './user.json';
import validation from './validation.json';
import errors from './errors.json'
import orders from './orders.json'
import locationDetails from './locationDetails.json'
import hints from './hints.json'
import shoppingCart from './shoppingCart.json'
import countries from './countries.json'
import exchangeRate from './exchangeRate.json'
import ticket from './ticket.json'

export default {
  actions,
  authorisation,
  client,
  dashboard,
  global,
  locale,
  product,
  productCategory,
  user,
  validation,
  errors,
  orders,
  hints,
  shoppingCart,
  countries,
  exchangeRate,
  locationDetails,
  ticket
};
