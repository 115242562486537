import AuthorisationGuard from '@/router/guards/AuthorisationGuard.js';

export default {
  path: '',
  beforeEnter: AuthorisationGuard,
  component: () => import('@/templates/Default'),
  children: [
    {
      path: '404',
      name: '404',
      component: () => import('@/modules/errorHandling/views/PageNotFound.vue'),
    },
    {
      path: '403',
      name: '403',
      component: () => import('@/modules/errorHandling/views/PageForbidden.vue'),
    },
  ],
};
