import router from '@/router';
import get from '@/modules/authorisation/api/profile.js';

const initialState = ({ token: null, profile: null });

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAuthorisationToken(state, token) {
      Object.assign(state, { token });
    },
    resetAuthorisation(state) {
      Object.keys(state).forEach((key) => delete state[key]);
      Object.assign(state, initialState);
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
  },
  actions: {
    logout({ commit }) {
      commit('resetAuthorisation');
      router.push({ name: 'login' });
    },
    async fetchProfile({ commit }) {
      const result = await get();
      commit('setProfile', {
        ...result.data.data
      });
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    username: (state) => state.profile.name,
    getCurrentUserId: (state) => state.profile.id,
    getCurrentClientId: (state) => state.profile?.client?.id,
  },
};
