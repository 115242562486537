import {
  faBox,
  faCheck,
  faCheckCircle,
  faCircle,
  faClipboardList,
  faEuroSign,
  faFolderTree,
  faQuestionCircle,
  faSearchPlus,
  faSignOutAlt,
  faStore,
  faTimes,
  faTimesCircle,
  faTicketAlt,
} from '@fortawesome/pro-solid-svg-icons';

const icons = [
    faBox,
    faCheckCircle,
    faTimesCircle,
    faFolderTree,
    faSignOutAlt,
    faClipboardList,
    faStore,
    faTimes,
    faCheck,
    faSearchPlus,
    faQuestionCircle,
    faCircle,
    faEuroSign,
    faTicketAlt
  ]
;

/*project specific icons*/
const identifiers = {
  enabled: ['fas', 'check-circle'],
  disabled: ['fas', 'times-circle'],
  product: ['fas', 'box'],
  productCategories: ['fas', 'folder-tree'],
  signOff: ['fas', 'sign-out-alt'],
  orders: ['fas', 'clipboard-list'],
  exchangeRate: ['fas', 'euro-sign'],
  locationDetails: ['fas', 'store'],

  approved: ['fas', 'check'],
  unknown: ['fas', 'question-circle'],
  denied: ['fas', 'times'],

  enlarge: ['fas', 'search-plus'],
  circle: ['fas', 'circle'],
  ticket: ['fas', 'ticket-alt']
};

export default {
  icons,
  identifiers,
};
