import Vue from 'vue';
import App from './App.vue';
import { i18n, mask, vuetify } from './plugins';
import router from './router';
import './scss/main.scss';
import store from '@/application/store';

Vue.prototype.$store = store;

Vue.config.productionTip = false;

const app = new Vue({
  router,
  vuetify,
  mask,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
