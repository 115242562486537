<template>
  <v-overlay
    @click="hideOverlay"
    :absolute="absolute"
    :opacity="opacity"
    :value="overlay">
    <div class="hide-button">
      <v-btn
        class="transparent"
        icon
        tile
        color="white"
        large
        @click="hideOverlay"
      >
        <v-icon large>$denied</v-icon>
      </v-btn>
    </div>
    <v-img
      :src="imgUrl"
      :lazy-src="imgUrl"
      max-height="80vh"
      max-width="80vh"
    />
  </v-overlay>
</template>

<script>
import EventBus from '@/application/eventBus.js';

export default {
  name: 'ExpandedImageOverlay',
  data: () => ({
    absolute: true,
    opacity: 0.85,
    overlay: false,
    imgUrl: '',
  }),
  created() {
    EventBus.$on('expandImage', (imgUrl) => {
      this.showOverlay(imgUrl.imgUrl);
    });
  },
  methods: {
    showOverlay(imgUrl) {
      this.overlay = true;
      this.imgUrl = imgUrl;
    },
    hideOverlay() {
      this.overlay = false;
    },
  },
};
</script>

<style>
.hide-button {
  position: absolute;
  z-index:  1;
  top:      0px;
  right:    0px;
}
</style>
