import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vuetifyIcons from './vuetify.js';
import idcpIcons from './idcp.js';
import kingscodeIcons from './kingscode.js';

const icons = [
  ...vuetifyIcons.icons,
  ...kingscodeIcons.icons,
  ...idcpIcons.icons,
];
library.add(...icons);

const identifiers = {
  ...vuetifyIcons.identifiers,
  ...kingscodeIcons.identifiers,
  ...idcpIcons.identifiers,
};

export default {
  values: Object.fromEntries(
    Object.entries(identifiers).map(
      ([key, icon]) => [key, {
        component: FontAwesomeIcon,
        props: { icon },
      }],
    ),
  ),
};
