import { camelToSnake, snakeToCamel } from '@/application/api/util/keyConverter.js';
import objectToFormData from '@/application/api/util/objectToFormDataConverter.js';
import Model from '@/application/models/model.js';
import Qs from 'qs';

/**
 * @param response
 * @returns {{}}
 */
function transformResponse(response) {
  if (typeof response === 'string' && response.length > 0) {
    try {
      const result = JSON.parse(response);
      return snakeToCamel(result);
    } catch (e) {
      return response;
    }
  }

  if (!response) {
    return {};
  }

  return response;
}

/**
 * @param data {undefined|*}
 * @return {FormData}
 */
function transformRequest(data) {
  if (data instanceof Model) {
    const isPutRequest = data._method === 'put';

    data = data.mapForRequest();

    if (isPutRequest) {
      data._method = 'put';
    }
  }

  if (data) {
    data = camelToSnake(data);
    data = objectToFormData(data);
  }

  return data;
}

function transformParams(params) {
  /**
   * for now i'm just replacing true/false with 1/0 in the request body we are using a function
   * that loops recursive trough all the values, except i'm guessing this is not needed for the querystring
   * at this time. We could always replace this code with something like objectToFormDataConverter
   */
  return Qs.stringify(camelToSnake(params)).replaceAll('true', '1').replaceAll('false', '0');
}

export {
  transformResponse,
  transformRequest,
  transformParams,
};
