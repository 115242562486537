<template>
  <v-app>
    <div v-if="loaded" class="fill-height background">
      <RouterView/>
    </div>
    <v-overlay v-else>
      <VProgressCircular indeterminate size="64"/>
    </v-overlay>
    <TheConfirmationDialog/>
    <TheSnackbarQueueHandler/>
    <ExpandedImageOverlay/>
  </v-app>
</template>

<script lang="js">
import TheConfirmationDialog from '@/components/TheConfirmationDialog.vue';
import TheSnackbarQueueHandler from '@/components/TheSnackbarQueueHandler.vue';
import { mapActions, mapGetters } from 'vuex';
import ExpandedImageOverlay from '@/modules/tickets/components/ExpandedImageOverlay.vue';

export default {
  name: 'App',
  components: {
    ExpandedImageOverlay,
    TheSnackbarQueueHandler,
    TheConfirmationDialog,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
    }),
  },
  watch: {
    colors() {
      this.setVuetifyColors(this.colors);
    },
  },
  async created() {
    /**
     * If the user is already logged in, in this session they never fetched it's profile so we need to do that now
     */
    await Promise.all([
      ...this.isLoggedIn ? [this.fetchProfile()] : [],
    ]);

    this.loaded = true;
  },
  methods: {
    ...mapActions({
      fetchProfile: 'authorisation/fetchProfile',
    }),
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
</style>
